@import "variables";


#contatti .alternating-image{
  padding: $padding-styling;
}

.padding-div div{
  padding: $padding-styling;;
}

input::placeholder {
  color: $secondary-dark-color; 
}