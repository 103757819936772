@import "./../variables";

.card {
    background-color: $main-dark-color;
    border: 0;
    margin: $padding-styling;
    border-radius: 0;
    box-shadow: $shadow;
    aspect-ratio: 1/1;
    min-width:min-content;
    color: $main-accent-color;
    .card-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .card-title{
        font-family: $impact-font;
        font-size: $font-size-bigger;
    }
    .button-link{
        font-family: $impact-font;
        font-size: $font-size-normal;
    }
}