@import "./../variables";


.container-fix {
  position: relative;
  width: 100%;
}

.container-fix img {
  width: 100%;
  display: block;
  /* Assicura che l'immagine si adatti correttamente */

}

.immagine {
  width: 100%;
  aspect-ratio: 20/9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width:576px) {
    aspect-ratio: 3/4;
  }
}
.light{
  z-index: 1;
}

.obscure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Copre completamente l'elemento padre */
  height: 100%;
  /* Copre completamente l'elemento padre */
  background-color: rgba(0, 0, 0);
  opacity: 0.6;
  /* Patina nera con opacità */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: $huge-icons;
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width:576px) {
      font-size: $font-size-biggest;
    }
  }
  h2{
    @media (max-width:576px) {
      font-size: $font-size-bigger;
    }
  }
  .text-transparent {
    color: rgba(255, 255, 255, 1);
    margin: 0;
    mix-blend-mode: overlay;
    /* Aggiunge l'effetto di sovrapposizione */

    font-family: $main-font;

  }
}

.author{
  font-size: 1.3rem;
}
