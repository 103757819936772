@import "./css/variables";



p,a,div,span{
    font-family: $main-font;
    font-size: $font-size-normal;
    color:$main-light-color;
    
}


h1,h2,h3,h4,h5,h6{
    font-family: $title-font;
}
blockquote>* {
    font-family: $blockquote-font;
}
li{
    list-style-type: none;
}
a{
    text-decoration: none;
    color:inherit;
    &:hover{
        color:$main-accent-color;
    }
}

// scrollbar
/* width */
::-webkit-scrollbar {
    width: 20px;
    
}
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: $shadow;
    background: $main-light-color;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    box-shadow: $shadow;
    background: $main-accent-color;
  }