@import "./../variables";

.scroll-container {
  overflow-x: auto;
  gap: 1rem;

  p {
    color: $white;
    max-width: 100%;
    white-space: normal; // Permette al testo di andare a capo
    overflow-wrap: break-word; // Spezza le parole lunghe se necessario
    word-wrap: break-word; // Compatibilità con i vecchi browser
    hyphens: auto; // Aggiunge trattini per andare a capo nelle parole lunghe
  }
}
.card{
  width: 450px;
}
