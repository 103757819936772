@import "./../variables";

nav.navbar{
  p,a,div,span{
    color:$white;
  }
    background-color:$main-light-color;
    box-shadow: $shadow;
    
    .nav-link:hover{
        color:$main-accent-color;
        text-shadow: $shadow;
    }
    .container-fluid{
        padding: 0 $padding-styling;
    }

}
#desktop-nav{
    min-width: 80vw;
    align-items: center;
    justify-content: space-between;
}
#logo-title{
    font-family: $logo-font;
    color:$white;
    text-shadow: $shadow;
}

#desktop-nav {

 
    .desktop-dropdown {
      position: relative;
      
      .desktop-link-with-dropdown {
        display: flex;
        align-items: center;
  
        .fa-chevron-down {
          margin-left: 5px;
          cursor: pointer;
          transition: transform 0.3s ease;
        }
  
        .fa-chevron-down.open {
          transform: rotate(180deg); // Rotazione dell'icona per indicare il menù aperto
        }
      }
  
      .custom-dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $main-light-color;
        // padding: 10px;
        border: $border;
        box-shadow: $shadow;
        z-index: 10;
        display: flex;
        flex-direction: column;
  
        .nav-item {
          padding: 0 $padding-styling/4;
          border-bottom: $border-table;
        }
  
        .nav-link {
          
          &:hover {
            color: $main-accent-color;
          }
        }
      }
    }
  }
  
  .mobile-sublink-container {

  }
  
  .sublink-item {
    padding-left: $padding-styling/4; // Ulteriore indentazione per sublink
  }
  