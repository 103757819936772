@import "./../variables";


.accordion,
.accordion-item,
.accordion-header,
.accordion-button,
.accordion-button:not(.collapsed) {
    background-color: inherit;
    box-shadow: none;
    border: none;
}

.accordion-button:not(.collapsed) {
    color: $main-accent-color;

}
.accordion-button:focus{
    box-shadow: $shadow;

}

.accordion {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-evenly;
}

.accordion-item {
    border: $shadow;
    width: 100%;
}