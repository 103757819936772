/* Import variabili globali */
@import "./../variables";

/* Stile generale del contenitore principale */
.introductory-paragraph {
  position: relative;
  overflow: hidden;
  /* Nasconde eventuali contenuti fuori dal viewport */


  /* Titolo principale */
  h2 {
    font-size: $font-size-biggest;
    text-align: center;
    z-index: 1;
    position: relative;
  }

  /* Titolo di sfondo (backtitle) */
  .backtitle {
    position: absolute;
    top: 0;
    left: 100%;
    /* Inizia fuori dal viewport a destra */
    font-size: $font-size-biggest;
    /* Dimensione maggiore per l'effetto */
    color: rgba($main-dark-color, 0.1);
    /* Colore chiaro per effetto sfondo */
    white-space: nowrap;
    /* Evita il ritorno a capo */
    z-index: -1;
    /* Posizionato dietro il contenuto principale */
    animation: scroll-backtitle 150s linear infinite;
    /* Animazione di scorrimento */
    pointer-events: none;
    /* Ignora interazioni del mouse */
  }

  /* Paragrafo di testo */
  p {
    text-align: justify;
    font-size: $font-size-normal;
  }

  /* Animazione per lo scorrimento del backtitle */
  @keyframes scroll-backtitle {
    from {
      transform: translateX(0);
      /* Inizio posizione normale */
    }

    to {
      transform: translateX(-1200vw);
      /* Scorre fuori dal viewport a sinistra */
    }
  }
}