@import "./variables";
@import "./mixin";

.resume-header,
.main-bicolumn {
  @include dflex-row-center(); // Mantiene la disposizione centrale e orizzontale
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  // Media query per schermi con larghezza maggiore di 576px
  @media (min-width: 576px) {
    flex-wrap: nowrap;
  }

}


.resume-wrapper-inner {

  p,
  a,
  li,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: small;
    font-family: $title-font;
  }

  h1,
  h2,
  h3 {
    text-transform: uppercase;
    font-weight: bold;
  }

  h1 {
    font-size: medium;
  }

  width: 210mm;
  margin: 200px auto;
  box-shadow: $shadow;
  overflow: hidden;
}

.resume-body,
.resume-header,
.resume-footer {
  padding: 0 1rem;
}

.resume-header {
  background-color: $secondary-light-color;
}

.experience-section{
  padding: 0 0.5rem;
}
.resume-timeline-item-header {
  @include dflex-row-center();
  justify-content: space-between;

}

.resume-timeline {
  padding-left: 2rem;
}

.resume-timeline:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 100%;
  background: #aab4c3;
  left: 6px;
  top: 4px;
  position: absolute;
}

.resume-timeline-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -32px;
  top: 3px;
  width: 15px;
  height: 15px;
  border: 4px solid #58677c;
  background: white;
  border-radius: 50%;
}

.skills-section {
  width: 60%;
}

.resume-skills-cat {
  font-weight: bold;
}

.signature {
  width: 60mm;

  img {
    width: 100%;
  }
}

/* resume.scss */

/* Imposta lo schermo intero per la modalità di anteprima */
.resume-wrapper.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
  background-color: white;
  max-width: 100dvw;
  overflow: auto;

  .resume-overlay{
    width: 100%;
    margin: 0;
    padding: 0;
  }

  /* Nasconde scrollbar per WebKit */
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Nasconde scrollbar per Firefox */
  scrollbar-width: 0;
  /* Firefox */

  .main-bicolumn{
    flex-wrap: nowrap;
  }
}


@media print {
  .print-invisible {
    display: none;
  }
  .resume-wrapper-inner{
    margin: 0;
    width: 100%;
    height: min-content;
    overflow-y: auto;
  }
  .resume-wrapper.fullscreen {
    position: absolute;
  }
}