
@import "./../variables";

.social-icons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: $padding-styling;
    font-size: $huge-icons/4;
}