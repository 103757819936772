@import "./../variables";


    .button-link{
        background-color: $secondary-light-color;
        color: $main-dark-color;
        border-radius: 0;
        border-width: 0;
        box-shadow: $shadow;
    }

    .button-link:hover{
        background-color: $main-accent-color;
    }
    
    .btn.button-link:active{
        background-color: $main-dark-color;
        color: $white;
        box-shadow: $darker-shadow;
    }
