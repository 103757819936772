@import "../variables";

.section-title{
    padding:$padding-styling 0 0 0;
    h1{
        font-family: $impact-font;
        font-size: $font-size-biggest;
    }
}
#PS.row.section-title{
    padding-left: 0;
    padding-right: 0;
}