@import '../variables';

.container-fix.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $main-light-color;
    position: relative;
    overflow: hidden;
    padding: $padding-styling*2 0;
}

.text-background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 0.1);
    font-size: $font-size-normal;
    overflow: hidden;
}

// Contenitore della riga con testo duplicato
.scroll-line {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    font-family: $title-font;
    position: relative;
    width: 200%; // Doppia larghezza per far scorrere due testi consecutivi
    animation: scroll-continuous 15s linear infinite; // Stessa durata per ogni riga
    color:$main-dark-color;
    &:nth-child(even) { // Alterna direzione per le righe pari
        animation-direction: reverse;
        text-align: right;
    }
}

// Keyframe per scorrimento continuo a sinistra
@keyframes scroll-continuous {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); // Scorre della metà per un effetto continuo
    }
}

.text-container {
    position: relative;
    text-align: center;
    color: $white;
    z-index: 1;
}

.text-top,
.text-down {
    font-size: $font-size-biggest;
    position: relative;
    z-index: 2;
    font-family: $impact-font;
}

// Testo centrale scorrevole
.text-middle {
    p,a,div,span{
        font-size: $font-size-normal;
    }
    font-weight: normal;
    white-space: nowrap;
    animation: scroll-continuous 15s linear infinite;
    z-index: 1;
}

.link-container {
    display: inline;
}

.link, .sublink {
    display: inline;
    color: $secondary-light-color;
    text-decoration: none;
    margin: 0 8px; // Spaziatura tra link e sublink
    padding: 3px 0;
}

.sublink {
    opacity: 0.9;
}
