@import '../variables';

.notification {
    background-color: $main-accent-color; /* Sfondo rosso */
    color: white;
    padding: 10px 20px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    border-radius: $radius-standard;
    box-shadow: $shadow;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}
