@import "./../../css/variables";

#hero-timeline {
    .alternating-image{
        padding: $padding-styling*2;
    }

    .alternating-row{
    background-color: white;
    }
    .alternating-text p{
        font-family: $blockquote-font;
        font-size: $font-size-normal;

    }
    .alternating-text h1{
        font-size: $font-size-biggest;
        font-family: $impact-font;

    }
}

.timestamp {
    padding: $padding-styling*2 0;
    background-color: $main-light-color;
    div, p{
        color: $white;
    }
    .text-left {
        font-family: $impact-font;
        font-size: $font-size-bigger;
        padding: 0 $padding-styling ;
    }

    .text-right {
        font-family: $impact-font;
        font-size: $font-size-biggest;
        padding: 0 $padding-styling ;
    }
    
    .text-right .accent-line{
        background-color: $main-accent-color;
        height:  $padding-styling/2;     
        opacity: 1;   
        margin: 0;
        border:0;
        align:right;
        width: 60%;
        margin-left: 40%;
    }
}

.text-right {
    text-align: right;
}
.timestamp {
    
        @media (max-width: 767px) {
            .text-right{
                text-align: left;
                .accent-line{
                    margin: 0;
                }
            }
            text-align: left; // Align text to the left on mobile
        }
    

}
