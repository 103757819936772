@import "variables";
@import "mixin";


.bicolumn {
    .row {
        .alternating-image {
            margin: 0;
            padding: 0;
        }

        .alternating-text {
            padding: $padding-styling;
        }
    }
}

.alternating-text {

    order: 1;
    text-align: center;
    @include dflex-center;
}

@media (min-width:576px) {
    .bicolumn:nth-child(odd) .alternating-image {
        order: 2;
    }

    .bicolumn:nth-child(odd) .alternating {
        order: 2;
    }
}

.bicolumn:nth-child(odd) .alternating-row {
    background-color: $main-light-color;
    *{
    color: $white;
    }
}

.bicolumn-text div {
    padding: $padding-styling;
}


.bicolumn-text .alternating-row {
    align-items: center;
}
.padded{
    padding: $padding-styling;
}