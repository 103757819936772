@mixin dflex-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@mixin dflex-row-center{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
}