@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Doto:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

// $main-light-color:#FEE4CD;
// $secondary-light-color:#b6db49;
// $secondary-light-color:#ccff00;
// $secondary-light-color:#b0ff32;
// $secondary-light-color:#d6ff33;

$main-light-color:#0d1b2a;

// FFE5CF

$secondary-light-color:#96bf91;


// $main-accent-color:#c3143f;
$main-accent-color:#489bfa;
$main-dark-color:black;


$secondary-dark-color:#a99889;
$white:white;

$title-font:'Newspaper', sans serif;
$main-font:"Architects daughter", serif;
$logo-font:'Reddit mono', serif;
$blockquote-font: 'Gentium Book Plus', serif;
$impact-font:"Special Elite", sans-serif;

$padding-styling:2rem;
$huge-icons:6rem;

$radius-standard:0;

$font-size-normal:$padding-styling/2;
$font-size-bigger:$font-size-normal*1.5;
$font-size-biggest:$font-size-bigger*1.8;

$shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px;

// $darker-shadow:rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px;
$darker-shadow:$main-accent-color 0px 6px 16px;


$border:1px solid $main-accent-color;
$border-table:1px solid $secondary-dark-color; //usare solo come bottom-border

// $shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
// $shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
// $shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px;
// $shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;



// opzioni colori:
//  #09EDCB
//  #3298AD
//  #FEE4CD
//  #64b6ac

