@import "variables";


#footer {
    background-color: $main-light-color;
    box-shadow: $shadow;
    padding: $padding-styling;
    
    div, span, h1, h2, h3, h4, h5, h6{
        color: $white;
    }

    ul {
        padding: 0;
    }

    a {
        color: $main-accent-color;
        text-decoration: none;
    }

    

    img {
        max-height: 3rem;
        border-radius: 10px;
        margin-bottom: $padding-styling/2;
    }
    .row{
        align-items: center;
        
    }

}

.icon {
    text-shadow: $shadow;
    font-size: $font-size-normal;
}
#subfooter {
    a {
        cursor: pointer;
    }
}